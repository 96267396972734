(function() {
	const email4i = function() {
		'use strict';

		const mainPanel = document.getElementById('iwc-mainEmailPanel');

		const init = function() {
			const form = document.getElementById("iwc-compose-email-form");

			dragElement.Bind(mainPanel.getElementsByClassName('iwc-main-panel-header')[0]);

			document.getElementById('iwc-exitEmailButton').addEventListener('click', function(e) {
				e.preventDefault();
				closeEmail();
			});
			
			document.getElementById('iwc-exitEmailButton').focus();

			const btn = document.getElementById('iwc-email-us');
			disableButton(btn, false);

			btn.addEventListener('click', function(e) {
				e.preventDefault();
				forms4i().formValidation(form,
					function() {
						ajaxActions.SendQuestion(form, true);
					},
					e
				);
			});

			if (getCatalogAlias()) {
				const element = document.getElementById('iwc-email-yourself');
				element.parentElement.classList.remove('hide');

				element.addEventListener('click', function(e) {
					e.preventDefault();
					forms4i().formValidation(form,
						function() {
							ajaxActions.SendQuestion(form, false);
						},
						e
					);
				});
			}
		}

		const closeEmail = function() {
			const element = mainPanel.parentElement;
			//element.classList.add('hide');
			element.classList.remove("open");
			
			while (element.firstChild) {
				element.removeChild(element.firstChild);
			}

			const script = document.getElementById('emailScript');
			script ? script.remove() : false;
			
			const button = document.querySelector('.iwc-contactPanel .iwc-openEmail');
			button.classList.remove('bkgdBlue');
			button.classList.remove('whiteLink');
			button.classList.add('btnToHyp');
			button.classList.add('pointer');
			button.setAttribute('aria-expanded', 'false');
			button.disabled = false;	
			button.focus();
		};

		const getCatalogAlias = function() {
			const productUrl = '/product/';
			const regex = new RegExp(productUrl + '.+/', 'i');
			if (regex.test(location.href)) {
				return regex.exec(location.href).toString().replace(productUrl, '').replace('/', '');
			}

			return '';
		}

		const showBusy = function(show) {
			const busy = document.getElementById('iwc-emailWaiting');

			if (show) {
				if(busy){
					busy.classList.remove('hide');
					busy.setAttribute('aria-busy', 'true');
				}
				disableButton(document.getElementById('iwc-email-us'), true);
				disableButton(document.getElementById('iwc-email-yourself'), true);
			} else {
				if(busy){
					busy.classList.add('hide');
					busy.setAttribute('aria-busy', 'false');
				}
				disableButton(document.getElementById('iwc-email-us'), false);
				disableButton(document.getElementById('iwc-email-yourself'), false);
}
		}

		const disableButton = function(btn, disable) {
			if (disable) {
				btn.classList.add('disabledBtn');
				btn.disabled = true;
			} else {
				btn.classList.remove('disabledBtn');
				btn.disabled = false;
			}
		}

		const ajaxActions = (function() {
			const sendQuestion = function(form, emailUs) {
				const headerToken = form.querySelector('input[name=__RequestVerificationToken]').value;
				showBusy(true);

				fetch('/chat/SendQuestion', {
					method: 'POST',
					credentials: 'same-origin',
					body: JSON.stringify({
						CustomerEmail: document.getElementById('iwc-email').value,
						Question: document.getElementById('iwc-question').value,
						Url: location.href,
						CatalogAlias: getCatalogAlias(),
						EmailUs: emailUs
					}),
					headers: { 'Content-Type': 'application/json', 'X-Requested-With': 'fetch', '__RequestVerificationToken': headerToken }
				}).then(function(response) {
					return (response.ok) ? response.json() : [];
				}).then(function(response) {
					showBusy(false);

					if (response.Message) {
						const element = form.getElementsByClassName("formHeading")[0];
						element.innerHTML = response.Message.replace(/Question/g, 'iwc-question').replace(/CustomerEmail/g, 'iwc-email');
						element.classList.remove("hide");
						element.querySelector('a').focus();
					} else {
						document.getElementById('iwc-emailSent').classList.remove('hide');
						if (emailUs) {
							document.getElementById('iwc-email-yourself-confirm').classList.add('hide');
							document.getElementById('iwc-email-us-confirm').classList.remove('hide');
						} else {
							document.getElementById('iwc-email-yourself-confirm').classList.remove('hide');
							document.getElementById('iwc-email-yourself-email').innerHTML = document.getElementById('iwc-email').value;
							document.getElementById('iwc-email-us-confirm').classList.add('hide');
						}
						document.getElementById('iwc-email').value = '';
						document.getElementById('iwc-question').value = '';
						document.getElementById('iwc-exitEmailButton').focus();
					}
				});
			}

			return {
				SendQuestion: sendQuestion
			};
		})();

		const dragElement = (function() {
			var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
			const element = mainPanel;

			const bind = function(header) {
				header.onmousedown = dragMouseDown;
			}

			const dragMouseDown = function(e) {
				e = e || window.event;
				e.preventDefault();
				// get the mouse cursor position at startup:
				pos3 = e.clientX;
				pos4 = e.clientY;
				document.onmouseup = closeDragElement;
				// call a function whenever the cursor moves:
				document.onmousemove = elementDrag;
			}

			const elementDrag = function(e) {
				e = e || window.event;
				e.preventDefault();
				// calculate the new cursor position:
				pos1 = pos3 - e.clientX;
				pos2 = pos4 - e.clientY;
				pos3 = e.clientX;
				pos4 = e.clientY;
				// set the element's new position:
				element.style.top = (element.offsetTop - pos2) + "px";
				element.style.left = (element.offsetLeft - pos1) + "px";
				element.style.bottom = "auto";
			}

			const closeDragElement = function() {
				// stop moving when mouse button is released:
				document.onmouseup = null;
				document.onmousemove = null;
			}

			return {
				Bind: bind
			}
		})();

		return {
			Init: init
		}
	}

	email4i().Init();
})();